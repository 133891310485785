import { Card, Heading, Stack, Text, Textarea, Button, Spinner } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { Notification } from "../../../helpers/Notification";
import { Api, baseApiParams } from "my-api-client-package";

import "./pages.scss";

export const Contact = () => {
    const { t } = useTranslation();
    const [message, setMessage] = useState("");
    const [spinner, setSpinner] = useState(false)

    const [alert, setAlert] = useState({
        type: "success",
        heading: "",
        message: null as string | null,
    });

    const api = new Api();
    const isButtonDisabled = !message.trim();

    const handleInputChange = (value: string) => setMessage(value);

    const handleSubmit = async () => {
        setSpinner(true)
        try {
            const res = await api.user.sendContactEmail(message, baseApiParams());
            if (res.status === 200) {
                setAlert({
                    type: "success",
                    heading: t("rate_my.message_sent_successfully"),
                    message: "",
                });
                setMessage("");
                setSpinner(false)
            } else {
                setSpinner(false)
                throw new Error(t("rate_my.failed_to_send_message"));
            }
        } catch (error) {
            setSpinner(false)
            setAlert({
                type: "error",
                heading: t("general.error"),
                message: t("rate_my.failed_to_send_message"),
            });
        }
    };

    return (
        <Stack
            flex="1"
            maxW="full"
            padding="20px 30px"
            bg="#eef2f6"
            borderRightWidth="1px"
        >
            <Card className="contact-card">
                <Stack className="contact-form">
                    <Heading className="contact-heading">
                        {t("rate_my.get_in_touch_with_us")}
                    </Heading>
                    <Text className="contact-hint">{t("rate_my.we_will_get_back")}</Text>

                    <Stack className="user-info-contact">
                        <Textarea
                            placeholder={t("rate_my.write_to_us")}
                            value={message}
                            onChange={(e) => handleInputChange(e.target.value)}
                        />
                    </Stack>

                    <Button
                        className="save-btn-"
                        background="#319795"
                        color="white"
                        isDisabled={isButtonDisabled}
                        onClick={handleSubmit}
                        paddingInlineStart={'unset'}
                        paddingInlineEnd={'unset'}
                    >
                        {t("rate_my.confirm_btn")}
                        {spinner && <Spinner
                            thickness='4px'
                            speed='0.90s'
                            emptyColor='gray.200'
                            color='#2C7A7B'
                            size='sm'
                        />}
                    </Button>
                </Stack>
            </Card>

            {alert.message || alert.heading && (
                <Notification
                    heading={alert.heading}
                    message={alert.message}
                    type={alert.type}
                    buttonText={t("smartslide.close_make_video_done")}
                    onClose={() => setAlert({ ...alert, message: null })}
                />
            )}
        </Stack>
    );
};