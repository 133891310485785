import { Stack, Text, Button, Avatar, Card, CardBody, CardHeader, Input, Textarea, CardFooter, Box, Container, WrapItem, FormControl } from "@chakra-ui/react"
import "./pages.scss"
import { useSelector, useDispatch } from 'react-redux'
import { useTranslation } from "react-i18next"
import { useEffect, useState } from "react"
import { StepTwo } from "../../register/steps/StepTwo"
import DropdownMenu from "../../../helpers/DropdownMenu"
import { updateLanguage } from "../../../actions"
import { HiCheckCircle } from "react-icons/hi";
import { IoDownloadOutline } from "react-icons/io5";
import { Dropzone } from "../../../helpers/Dropzone"
import { Api, UserDto, handleApiError, baseApiParams } from 'my-api-client-package'
import { useNavigate } from "react-router-dom"
import { CONSTANTS } from "../../../constants"
import { MiscFunctions } from 'helper-functions-package';
import { Notification } from "../../../helpers/Notification";
export const Profile = () => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const token = useSelector((state: any) => state.token);
    const userObj = useSelector((state: any) => state.user);
    const [changeUserName, setChangeUserName] = useState<string>(userObj.username)
    const [changeShortCv, setChangeShortCv] = useState<string>(userObj.shortCv)
    const [changedEmail, setChangedEmail] = useState<string>(userObj.email)
    const navigate = useNavigate();

    const eduLevel = useSelector((state: any) => state.eduLevel)
    const specialistArea = useSelector((state: any) => state.specialistArea)
    const specialization = useSelector((state: any) => state.specialization)
    const languages = [
        { key: t('rate_my.language_de') },
        { key: t('rate_my.language_en') }
    ]
    const language = useSelector((state: any) => state.language || (userObj.preferences !== null && userObj.preferences !== "" && userObj.preferences[0] === "english" ? "English" : "Deutsch"));
    const [alertMessage, setAlertMessage] = useState<string | null>(null)
    const [alertHeading, setAlertHeading] = useState<string | null>(null)
    const [buttonText, setButtonText] = useState<string | null>(null)

    const [alertType, setAlertType] = useState<string>('success')

    const [imageSrc, setImageSrc] = useState<string | null>(null);
    const [imageUrl, setImageUrl] = useState<string | null>(null);
    const [disabledSaveBtnInfo, setDisabledSaveBtnInfo] = useState<boolean>(true);
    const [disabledSaveBtnEdu, setDisabledSaveBtnEdu] = useState<boolean>(true);
    const [infoNotify, setInfoNotify] = useState<boolean>(false);

    const engVersion = "67375cbee8cff4eb470c0729";
    const gerVersion = "672db135127b502557ba057b";
    const handleImageUrl = (url: string) => {
        setImageUrl(url);
    }
    const handleFilesChange = (files: File[]) => {
        if (files.length > 0) {
            const reader = new FileReader();
            reader.onload = (event) => {
                setImageSrc(event.target?.result as string); // Set the base64 image URL
            };
            reader.readAsDataURL(files[0]);
            if (MiscFunctions.emailValidation(changedEmail)) {
                setDisabledSaveBtnInfo(false);
            }
        }
    };

    const updateUserInfo = async () => {
        const userData: UserDto | any = {
            id: userObj.id,
            email: userObj.email,
            username: changeUserName,
            shortCv: changeShortCv,
            avatar: imageUrl
        }
        const api = new Api();
        if (token) {
            try {
                const user = await api.user.saveUser(userData, baseApiParams());
                dispatch({ type: CONSTANTS.CHANGE_USER, payload: user.data });
                setAlertType('success')
                if (changedEmail !== userObj.email) {
                    const emailData = await api.user.changeEmail({ mail: changedEmail }, baseApiParams());
                    setInfoNotify(true);
                    setAlertHeading(t("rate_my.notification_confirm_email_heading"))
                    setAlertMessage(t("rate_my.notification_confirm_email_message"));
                    setButtonText("Ok")
                }else {
                    setAlertHeading("")
                    setAlertMessage(t("messages.information_updated_successfully"));
                    setButtonText("Skip")
                }
            } catch (err) {
                handleApiError(err, navigate)
                setAlertType('error')
                setAlertMessage(t("messages.error"))
            }
        }
        setDisabledSaveBtnInfo(true);
    }

    const updateEducationInfo = async () => {
        const data: UserDto | any = {
            email: userObj.email,
            id: userObj.id,
            education: eduLevel,
            speciality: specialistArea,
            specialization: specialization,
        }
        const api = new Api();
        if (token) {
            try {
                const user = await api.user.saveUser(data, baseApiParams());
                setAlertType('success')
                setAlertMessage(t("messages.information_updated_successfully"))
            } catch (err) {
                handleApiError(err, navigate)
                setAlertType('error')
                setAlertMessage(t("messages.error"))
            }
        }
        setDisabledSaveBtnEdu(true);
    }

    const handleDownload = async (id: any) => {
        console.log(id)
        const api = new Api();
        if (token) {
            try {
                const mediaName = await api.media.getMedia(id, baseApiParams());
                const mediaData = await api.download.getMediaDownload(id, baseApiParams())
                const dataType = "application/pdf";
                const response = await fetch(mediaData.data.message);
                const data = await response.arrayBuffer();
                const blob = new Blob([data], { type: dataType });
                const url = window.URL.createObjectURL(blob);
                const tempLink = document.createElement("a");
                tempLink.href = url;
                tempLink.download = mediaName.data.description;
                document.body.appendChild(tempLink);
                tempLink.click();
                document.body.removeChild(tempLink);
                window.URL.revokeObjectURL(url);
                setAlertType('success')
                setAlertMessage(t("messages.information_download_successfully"))
            } catch (err) {
                handleApiError(err, navigate)
                setAlertType('error')
                setAlertMessage(t("messages.error"))
            }
        }
    };
    return (
        <Stack flex="1"
            maxW={{ base: 'full' }}
            padding={"20px 30px"}
            bg="#eef2f6"
            borderRightWidth="1px"
        >
            <Text className="profil-heading">{t('rate_my.profile')}</Text>
            <Card width="inherit" padding={"20px 31px 20px 15px"}>
                <CardHeader padding={"0px 0px 0px 0px"}>
                    <Text className="profil-info">{t('rate_my.your_profile')}</Text>
                </CardHeader>
                <CardBody padding={"0 0 0 200px"}>
                    <Text className="user-info-data">{t('rate_my.username')}</Text>
                    <Input defaultValue={`${userObj.username}`} onChange={(event) => {
                        setChangeUserName(event.target.value);
                        if (MiscFunctions.emailValidation(changedEmail)) {
                            setDisabledSaveBtnInfo(false);
                        }
                    }} />
                    <Text className="user-info-data" padding={"16px 0px 0px 0px"}>{t('rate_my.short_cv')}</Text>
                    <Textarea placeholder={t('rate_my.hint_for_short_vita')} defaultValue={`${userObj.shortCv}`} onChange={(event) => {
                        setChangeShortCv(event.target.value);
                        if (MiscFunctions.emailValidation(changedEmail)) {
                            setDisabledSaveBtnInfo(false);
                        }
                    }} />
                    <Text className="profil-pic" padding={"16px 0px 0px 0px"}>{t("rate_my.profile_picture")}</Text>

                    <Box as="section" bg="bg.surface" display={'flex'} flexDirection={'column'} gap={"6px"} >
                        <Container maxW="lg" display={'flex'} flexDirection={'row'} gap={"16px"} maxWidth={'100%'} padding={0}>
                            <WrapItem display={'flex'} flexDirection={'column'} alignItems={'center'}>
                                <Avatar name={`${userObj.firstname} ${userObj.lastname}`} size={'xl'} src={imageSrc ? imageSrc : userObj.avatar} />
                            </WrapItem>
                            <FormControl id="file">
                                <Dropzone fileFormats={['JPG', 'PNG']} fileSizeLimit={3} selectedFiles={(e) => handleFilesChange(e)} handleImageUrl={(e) => handleImageUrl(e)} />
                            </FormControl>
                        </Container>
                    </Box>
                    <Text className="user-info-data">{t('rate_my.email')}</Text>
                    <Input defaultValue={`${userObj.email}`} onChange={(event) => {
                        setChangedEmail(event.target.value);
                        setDisabledSaveBtnInfo(true);
                        if (MiscFunctions.emailValidation(event.target.value)) {
                            setDisabledSaveBtnInfo(false);
                        }
                    }} />
                </CardBody>
                <CardFooter padding={"58px 0px 16px 0px"} justifyContent={"end"}>
                    <Button variant='outline' className={`save-btn-${disabledSaveBtnInfo ? 'disable' : ''}`} color="white"
                        onClick={() => {
                            updateUserInfo();
                        }}>{t('rate_my.save_btn')}</Button>
                </CardFooter>
                <CardHeader padding={"0px"}>
                    <Text className="profil-info">{t('general.education')}</Text>
                </CardHeader>
                <CardBody padding={"0px 0px 0px 190px"}>
                    <StepTwo showInfo={true} setDisabledSaveBtnEdu={setDisabledSaveBtnEdu} />
                </CardBody>
                <CardFooter padding={"58px 0px 16px 0px"} justifyContent={"end"}>
                    <Button className={`save-btn-${disabledSaveBtnEdu ? 'disable' : ''}`}
                        color="white" variant='outline' onClick={() => {
                            updateEducationInfo();
                        }}>{t('rate_my.save_btn')}</Button>
                </CardFooter>
                <CardHeader padding={"0px"}>
                    <Text className="conditions-heading">{t('rate_my.conditions')}</Text>
                    <Stack direction={"row"} alignItems={"baseline"} padding={"15px 0px 0px 0px"}>
                        <HiCheckCircle color="teal" />
                        <Text className="date-info">{"seit 16.Jan.2024"}</Text>
                    </Stack>
                </CardHeader>
                <CardBody padding={"0px 0px 0px 0px"}>
                    <Stack className="conditions-content">
                        <Text className="language-text">{t('general.language')}</Text>
                        <Stack className="language-dropdown" padding={"50px 0px 30px 0px"}>
                            <DropdownMenu
                                label={language}
                                value={language?.key}
                                options={languages}
                                onSelect={(e) => {
                                    dispatch(updateLanguage(e));
                                }}
                            />
                        </Stack>
                        <Button className="save-btn" background={"#319795"} color={"white"} leftIcon={<IoDownloadOutline />} onClick={() => {
                            if (language === "Deutsch") {
                                handleDownload(gerVersion);
                            } else {
                                handleDownload(engVersion)
                            }
                        }}>{t('rate_my.download_btn')}</Button>
                    </Stack>
                </CardBody>
            </Card>
            {alertMessage && <Notification heading={alertHeading} message={alertMessage} type={alertType} buttonText={buttonText} onClose={() => setAlertMessage(null)} infoNotify={infoNotify}/>}
        </Stack>
    )
}